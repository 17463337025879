.team-detail-picture {
  text-align: center;
  margin-top: 0.7rem;
  margin-bottom: 3rem;

  & img {
    width: 100%;
    height: auto;
  }
}
