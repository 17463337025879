//BOOTSTRAP
// Breakpoints
$screen-sm: 768px;
$screen-md: 991px;
$screen-lg: 1200px;
$screen-sm-min: $screen-sm;
$screen-md-min: $screen-md;
$screen-lg-min: $screen-lg;
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1400px
);

//COLORS
$primary-color-transparant: hsla(210, 100, 40, 0.9);
$primary-color: hsl(210, 100, 40);

// HERO
$hero-viewport-height: 35vh;
$hero-margin-bottom: 3rem;
// FOOTER
$footer-margin-top: 3rem;
$footer-padding-top: 2rem;
