.loading-target {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999999;
    pointer-events: none;
  }
  &:after {
    content: "";
    display: inline-block;
    border-radius: 50%;

    width: 3.5rem;
    height: 3.5rem;
    margin: 0 auto;
    font-size: 0.8rem;

    text-indent: -9999em;
    border-top: 0.5em solid rgba(black, 0.1);
    border-right: 0.5em solid rgba(black, 0.1);
    border-bottom: 0.5em solid rgba(black, 0.1);
    border-left: 0.5em solid black;
    transform: translateZ(0);
    animation: target-loading 1.1s infinite linear;

    position: absolute;
    top: calc(50% - 1.75rem);
    left: calc(50% - 1.75rem);
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
    z-index: 9999999;

    @-webkit-keyframes target-loading {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    @keyframes target-loading {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  @at-root body.loading-target {
    &:after {
      position: fixed;
    }
  }
  // @at-root html.page-edit & {
  //   &:before,
  //   &:after {
  //     display: none;
  //   }
  // }
}

.loading {
  &:before {
    display: block;
    opacity: 1;
    pointer-events: all;
  }
  &:after {
    display: block;
    opacity: 1;
    transition-delay: 0.5s;
    animation-play-state: running;
  }
}
