.next-game {
  font-size: 1.8rem;
  font-weight: 100;
  // background-color: rgba(43, 43, 43, 0.2);
  text-align: center;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;

  @media (max-width: $screen-sm) {
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  }

  &-home {
    width: 40%;
    float: left;

    @media (max-width: $screen-sm) {
      width: 100%;
      float: none;
    }
  }

  &-guests {
    float: right;
    width: 40%;

    @media (max-width: $screen-sm) {
      width: 100%;
      float: none;
    }
  }

  &-game-information {
    font-size: 1rem;

    & > span:last-child {
      padding-left: 5%;
    }

    @media (max-width: $screen-sm) {
      margin-bottom: 1.2rem;
    }
  }
}

.game-unavailable {
  display: none;
  margin: 2rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: lighter;
}
