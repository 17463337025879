.news-item {
  background: white;
  // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border: 1px solid rgba(235, 235, 235, 1);
  margin-bottom: 1.5rem;

  overflow: auto;
  padding: 1.5rem;

  &-title {
    font-size: 1.5rem;
  }

  &-thumbnail {
    float: left;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @media (min-width: $screen-sm) {
      margin-right: 1rem;
      width: auto;
    }
  }

  &.spotlight {
    font-size: 1.2rem;

    & > p {
      font-size: 2rem;
      font-weight: bolder;
    }
  }

  & span {
    float: right;
    margin-top: -3.5rem;
    font-weight: bold;

    @media (max-width: $screen-sm) {
      float: none;
    }
  }
}

.post {
  & > p {
    margin-top: 1rem;
    float: right;
    width: 100%;
    @media (min-width: $screen-sm) {
      margin-top: -2rem;
      width: inherit;
    }
  }

  &-content {
    margin-top: 2rem;
    & img {
      max-width: 100%;
    }
  }

  // & nav {
  //   text-align: center;

  //   & div:first-child {
  //     margin-bottom: 1rem;
  //     text-align: left;

  //     @media (min-width: $screen-lg) {
  //       margin-bottom: 0;
  //     }
  //   }

  //   & div:last-child {
  //     margin-top: 1rem;
  //     text-align: right;

  //     @media (min-width: $screen-lg) {
  //       margin-bottom: 0;
  //     }
  //   }
  // }
}
