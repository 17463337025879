@import "globals/variables";

@import "atoms/atoms";

@import "molecules/molecules";

@import "organisms/organisms";

html {
  // font-family: "Exo", sans-serif;
}

body {
  // background-color: rgb(240, 240, 240);
  font-family: "Montserrat", sans-serif;
}

.post-content {
  font-size: 15px;
}

.welcome-text {
  margin: 3rem 0;
}

.information {
  & > h1 {
    margin-bottom: 2rem;
  }
}

.wp-block-table {
  // background-color: pink;
  width: 100%;
  & tr {
    @media (min-width: $screen-md) {
      font-size: 1.2rem;
    }

    & td {
      border: 1px solid black;
      padding: 0.5rem 0.3rem;
      text-align: center;

      @media (min-width: $screen-md) {
        padding: 1rem;
      }
    }
  }
}
