.event {
  margin-bottom: 1.5rem;
  border: 1px solid #ebebeb;

  & > a {
    &:hover {
      text-decoration: none;
    }
  }

  &-body {
    position: relative;
    font-size: 2rem;
    font-weight: bolder;
    padding: 1rem 1rem 2rem 1rem;

    &-image {
      min-height: 14rem;
      align-items: center;
      justify-content: center;
      display: flex;
      // flex-direction: column;

      &.portrait {
        flex-direction: row;
      }

      &.landscape {
        flex-direction: column;
      }

      & > img {
        max-width: 100%;
      }
    }

    &-information {
      padding: 0 1rem;
      text-align: center;
      color: black;
    }
  }

  &-overlay {
    position: absolute;
    width: calc(100% - 30px);
    opacity: 0.8;
    font-size: 2rem;
    text-align: center;
    color: white;
    margin-top: -2rem;
    display: flex;
    flex-direction: row;

    & > div {
      margin-left: auto;
      margin-right: auto;
      padding: 0 1rem;
      background: red;
    }

    @media (min-width: $screen-sm) {
    }

    @media (min-width: $screen-md) {
    }
  }
}
