.cookies {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  text-align: center;
  background: #343a40;
  border-top: 2px solid #ffffff;

  z-index: 99;

  & > p {
    margin: 0;
    color: #e3dfd4;

    & a {
      color: #e3dfd4;
      text-decoration: underline;
    }
  }

  &-button {
    cursor: pointer;
    background-color: $primary-color;
    border: none;
    margin-left: 3rem;
    padding: 0.5rem 2rem;
    color: #e3dfd4;
    display: inline-block;
  }
}
