.player {
  text-align: center;
  margin-bottom: 1.88rem;
  margin-left: 2.7rem;

  max-width: 75%;
  height: 20rem;

  & img {
    height: 100%;
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }

  &-info {
    position: absolute;
    top: 65%;
    font-size: 1rem;
    color: #fff;
    background: $primary-color;
    left: 2.7rem;
    padding-left: 2rem;
    padding-right: 2rem;
    transform: skewX(-15deg);

    // @media (min-width: $screen-lg) {
    //   top: 65%;
    //   left: 2.7rem;
    //   padding-left: 2rem;
    //   padding-right: 2rem;
    // }
  }

  &-number {
    padding-right: 1rem;
  }

  &-position {
    position: absolute;
    top: 71.6%;
    font-size: 1rem;
    color: #0066cc;
    background: #fff;
    border: 1px solid #0066cc;
    left: 3.65rem;
    padding-left: 2rem;
    padding-right: 2rem;

    // @media (min-width: $screen-lg) {
    //   top: 71.6%;
    //   left: 3.65rem;
    //   padding-left: 2rem;
    //   padding-right: 2rem;
    // }
  }
}
