.team {
  text-align: center;

  &-title {
    color: #fff;
    background-color: $primary-color;

    position: absolute;
    width: calc(100% - 30px);
    opacity: 0.95;
    font-size: 2rem;
    text-align: center;
    color: white;
    margin-top: -5rem;
    display: flex;
    flex-direction: row;

    & > div {
      margin-left: auto;
      margin-right: auto;
    }
  }

  & img {
    width: 100%;
    height: auto;

    @media (min-width: $screen-lg) {
      min-height: 23rem;
    }
  }
}
