.hero {
  background-size: cover;
  background-position: top;
  overflow: auto;
  background-image: url(../img/teams.jpg);
  filter: blur(5px);
  min-height: 16rem;
  margin-bottom: 3rem;

  @media (min-width: $screen-sm) {
    min-height: 20rem;
  }

  @media (min-width: $screen-md) {
    min-height: 25rem;
  }

  &-title {
    position: absolute;
    margin-left: 10%;
    font-size: 4rem;
    // color: #ffffff;
    top: 6rem;

    @media (min-width: $screen-sm) {
      font-size: 6rem;
      margin-left: 10%;
      top: 10rem;
      // color: #000000;
    }

    @media (min-width: $screen-md) {
      font-size: 8rem;
      margin-left: 10%;
      top: 12rem;
      // color: #000000;
    }

    @media (min-width: $screen-lg) {
      margin-left: 15%;
      // color: #000000;
    }
  }

  &-post {
    background-image: url(../img/hero.jpg);
    max-height: 50vh;
    min-height: 50vh;

    &-title {
      margin-left: 5%;
      font-size: 3rem;
    }
  }

  &-team {
    background-position: top;
    background-image: url(../img/hero.jpg);
    max-height: 50vh;
    min-height: 85vh;
  }
}
