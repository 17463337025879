.score-card {
  margin: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1rem;

  border: solid 1px black;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  &-game-information {
    padding: 0.8rem 0.5rem 0.8rem 0.5rem;
    background-color: $primary-color;
    color: #fff;
  }

  &-home {
    padding: 0.8rem 0.5rem 0rem 0.5rem;

    &.home-team {
      font-weight: 700;
    }
  }

  &-guests {
    padding: 0rem 0.5rem 0.8rem 0.5rem;

    &.home-team {
      font-weight: 700;
    }
  }

  & span {
    float: right;
    margin-top: -0.85rem;
    font-weight: initial;
  }
}
