.footer {
  margin-top: $footer-margin-top;
  padding-top: $footer-padding-top;
  background-color: rgb(52, 58, 64);
  color: #fff;

  &-madeby {
    text-align: center;
    padding-bottom: 2rem;
  }

  ul {
    list-style: none;
    padding-left: 0;

    li a {
      color: #ffffff;
    }
  }

  &-left {
    @media (max-width: $screen-sm) {
      text-align: center;
    }
  }

  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;

    @media (max-width: $screen-sm) {
      text-align: center;
    }
  }
}
