.sponsor {
  display: flex;
  min-height: 15rem;
  align-items: center;
  justify-content: center;

  &.landscape {
    flex-direction: row;

    & a {
      width: inherit;
      height: inherit;
    }

    & img {
      width: 100%;
      height: auto;
    }
  }

  &.portrait {
    flex-direction: column;

    & img {
      width: auto;
      height: 100%;
    }
  }
}
