.sponsor-detail {
  margin-top: 2rem;

  &-information {
    text-align: center;
    margin-top: 2rem;

    @media (min-width: $screen-md) {
      margin-top: 0;
    }

    & > address {
      margin-top: 2rem;
    }
    & > img {
      width: 100%;
      height: auto;
    }
  }
}
