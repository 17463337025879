.sidebar-team {
  // margin-top: -3rem;

  .results {
    &-no {
      text-align: center;
    }
  }

  .result-title {
    & p {
      text-align: center;
      font-size: 1.7rem;
    }
  }

  & span {
    font-size: 2rem;
  }

  @media (max-width: $screen-lg) {
    margin-top: -1rem;
  }
}
