.dropdown {
  &-menu {
    background-color: #343a40;
  }
  &-item {
    color: white;
    &:active {
      background-color: $primary-color;
    }

    &:focus {
      background-color: $primary-color;
    }

    &:hover {
      color: white;
      background-color: $primary-color;
    }
  }
}
